// google-analytics
window.dataLayer = window.dataLayer || [];

function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "UA-143174571-1");
// End google-analytics

// Google Tag Manager
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-M98R92T');
// End Google Tag Manager



(function ($) {
  $(".nav.navbar-nav a").each(function (idx, elem) {
    if (
      (window.location.pathname === "/pointcheck02.html" &&
        elem.pathname === "/pointcheck01.html") ||
      elem.pathname === window.location.pathname
    ) {
      $(".nav.navbar-nav li").removeClass("active");
      $(this)
        .parent()
        .addClass("active")
        .parents("li")
        .addClass("active");
    }
  });
  function detectMobilePc() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      $('.link-appPower').attr('href', 'http://onelink.to/zp898k');
      $('.link-appDot').attr('href', 'http://onelink.to/3gafwb');
    }
    else {
      $('.link-appPower').removeAttr('href');
      $('.link-appDot').removeAttr('href');
    }
  }
  function mediaSize() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      $('.intro-mascot').hide().eq(0).show();
      $('.btn-mascot').on('click', function () {
        $('.intro-mascot').hide();
        $('#detail0' + $(this).attr('target')).show();
      })

    } else {
      $('.intro-mascot').show();
    }
  }
  $('.toTop').on('click', function (e) {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 0,
    }, 700)
  });
  $('.ques').on('click', function (e) {
    e.preventDefault();
    $(this).parent('.qabox').toggleClass('active').siblings('.qabox').removeClass('active');
  });
  window.addEventListener('resize', mediaSize, false);
  detectMobilePc();
  mediaSize();

  // bs3  讓 modal 的圖片垂直置中
  function alignModal() {
    var modalDialog = $(this).find(".modal-dialog");

    // Applying the top margin on modal to align it vertically center
    modalDialog.css("margin-top", Math.max(0, ($(window).height() - modalDialog.height()) / 2));
  }
  // Align modal when it is displayed
  $(".modal").on("shown.bs.modal", alignModal);

  // Align modal when user resize the window
  $(window).on("resize", function () {
    $(".modal:visible").each(alignModal);
  });
  let iconAddNum = 0;
  $('.btn-add').on('click', function (e) {
    e.preventDefault();
    if (iconAddNum > 3) return;
    let str = `
      <li class="form-group form-group-flex">
      <label>
        <input type="number" placeholder="請輸入您的電號" class="form-control">
      </label>
      <button type="button" class="txt-primary btn-remove">
        <span class="material-icons">remove_circle</span>
      </button>
    </li>
    `;
    $('.form-groups').append(str);
    iconAddNum++;
  })
  $('.form-groups').on('click', '.btn-remove', function () {
    iconAddNum--;
    $(this).parent().remove();
  })

  $('.news-article').each(function (key, value) {
    if (key == 0) {
      $(this).find('.news-article-cont').toggleClass('active');
      $(this).toggleClass('active');
    }

    $(this).on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('.news-article-cont').removeClass("active");
      }
      else {
        $('.news-article.active').removeClass('active');
        $('.news-article-cont.active').removeClass('active');
        $(this).addClass('active');
        $(this).find('.news-article-cont').addClass("active");
      }
      $('html, body').animate({
        scrollTop: $(this).offset().top
      }, 300);
    });
  });
  $('.btn-get-recode').on('click', function (e) {
    e.preventDefault();
    $(".record-result").slideDown();
    $('.record-check').hide();
  });

  // 中獎名單頁
  $('.winlists-article-cont a').on('click', function (e) {
    e.stopPropagation();
  });
  $('.winlists-article-cont')
    .eq(0)
    .show();
  var effect1 = function (obj) {
    return $(obj)
      .parent()
      .siblings()
      .find('.winlists-article-cont')
      .hide();
  };
  var effect2 = function (obj) {
    return $(obj)
      .children('.winlists-article-cont')
      .toggle();
  };
  $('.winlists-article').on('click', function (e) {
    // console.log($(this).offset().top)
    e.stopPropagation();
    var that = this;
    $.when(effect2(this), effect1(this)).done(function () {
      $('html, body').animate(
        {
          scrollTop: $(that).offset().top
        },
        300
      );
    });
  });
})(jQuery);